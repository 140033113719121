import { green, grey } from "@mui/material/colors";
import merge from "deepmerge";
import { THEMES } from "../constants";

const customBlue = {
  50: "#f5f9ff",
  100: "#d0e1fd",
  200: "#abc9fb",
  300: "#85b2f9",
  400: "#609af8",
  500: "#3b82f6",
  600: "#326fd1",
  700: "#295bac",
  800: "#204887",
  900: "#183462 ",
};

const customGreeen = {
  50: '#f4fcf7',
  100: '#caf1d8',
  200: '#a0e6ba',
  300: '#76db9b',
  400: '#4cd07d',
  500: '#22c55e',
  600: '#1da750',
  700: '#188a42',
  800: '#136c34',
  900: '#0e4f26',
};

const customYellow = {
  50: '#fefbf3',
  100: '#faedc4',
  200: '#f6de95',
  300: '#f2d066',
  400: '#eec137',
  500: '#eab308',
  600: '#c79807',
  700: '#a47d06',
  800: '#816204',
  900: '#5e4803',
};

const customYellowWhite = {
  50: '#F7F7F5',
  100: '#bcc961',
  200: '#bcc961',
  300: '#bcc961',
  400: '#bcc961',
  500: '#bcc961',
  600: '#bcc961',
  700: '#bcc961',
  800: '#6B6B82',
  900: '#6B6B82',
};


const customCyan = {
  50: '#f3fbfd',
  100: '#c3edf5',
  200: '#94e0ed',
  300: '#65d2e4',
  400: '#35c4dc',
  500: '#06b6d4',
  600: '#059bb4',
  700: '#047f94',
  800: '#036475',
  900: '#024955',
};

const customPink = {
  50: '#fef6fa',
  100: '#fad3e7',
  200: '#f7b0d3',
  300: '#f38ec0',
  400: '#f06bac',
  500: '#ec4899',
  600: '#c93d82',
  700: '#a5326b',
  800: '#822854',
  900: '#5e1d3d',
};

const customIndigo = {
  50: '#f7f7fe',
  100: '#dadafc',
  200: '#bcbdf9',
  300: '#9ea0f6',
  400: '#8183f4',
  500: '#6366f1',
  600: '#5457cd',
  700: '#4547a9',
  800: '#363885',
  900: '#282960',
};

const customTeal = {
  50: '#f3fbfb',
  100: '#c7eeea',
  200: '#9ae0d9',
  300: '#6dd3c8',
  400: '#41c5b7',
  500: '#14b8a6',
  600: '#119c8d',
  700: '#0e8174',
  800: '#0b655b',
  900: '#084a42',
};

const customOrange = {
  50: '#fff8f3',
  100: '#feddc7',
  200: '#fcc39b',
  300: '#fba86f',
  400: '#fa8e42',
  500: '#f97316',
  600: '#d46213',
  700: '#ae510f',
  800: '#893f0c',
  900: '#642e09',
};
const customBlueGray = {
  50: '#f7f8f9',
  100: '#dadee3',
  200: '#bcc3cd',
  300: '#9fa9b7',
  400: '#818ea1',
  500: '#64748b',
  600: '#556376',
  700: '#465161',
  800: '#37404c',
  900: '#282e38',
};
const customPurple = {
  50: '#fbf7ff',
  100: '#ead6fd',
  200: '#dab6fc',
  300: '#c996fa',
  400: '#b975f9',
  500: '#a855f7',
  600: '#8f48d2',
  700: '#763cad',
  800: '#5c2f88',
  900: '#432263',
};

const customRed = {
  50: '#fff5f5',
  100: '#ffd0ce',
  200: '#ffaca7',
  300: '#ff8780',
  400: '#ff6259',
  500: '#ff3d32',
  600: '#d9342b',
  700: '#b32b23',
  800: '#8c221c',
  900: '#661814',
};



const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: green[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: green[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: "#FFF",
    background: green[700],
    header: {
      color: "#FFF",
      background: green[800],
      brand: {
        color: "#FFF",
      },
    },
    footer: {
      color: "#FFF",
      background: green[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
};


const purpleVariant = merge(defaultVariant, {
  name: THEMES.PURPLE,
  palette: {
    primary: {
      main: customPurple[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customPurple[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customPurple[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customPurple[700],
    header: {
      color: "#FFF",
      background: customPurple[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customPurple[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const blueGrayVariant = merge(defaultVariant, {
  name: THEMES.BLUE_GRAY,
  palette: {
    primary: {
      main: customBlueGray[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlueGray[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customBlueGray[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customBlueGray[700],
    header: {
      color: "#FFF",
      background: customBlueGray[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customBlueGray[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const orangeVariant = merge(defaultVariant, {
  name: THEMES.ORANGE,
  palette: {
    primary: {
      main: customOrange[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customOrange[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customOrange[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customOrange[700],
    header: {
      color: "#FFF",
      background: customOrange[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customOrange[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});


const tealVariant = merge(defaultVariant, {
  name: THEMES.TEAL,
  palette: {
    primary: {
      main: customTeal[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customTeal[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customTeal[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customTeal[700],
    header: {
      color: "#FFF",
      background: customTeal[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customTeal[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const redVariant = merge(defaultVariant, {
  name: THEMES.RED,
  palette: {
    primary: {
      main: customRed[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customRed[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customRed[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customRed[700],
    header: {
      color: "#FFF",
      background: customRed[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customRed[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});


const indigoVariant = merge(defaultVariant, {
  name: THEMES.INDIGO,
  palette: {
    primary: {
      main: customIndigo[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customIndigo[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customIndigo[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customIndigo[700],
    header: {
      color: "#FFF",
      background: customIndigo[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customIndigo[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const pinkVariant = merge(defaultVariant, {
  name: THEMES.PINK,
  palette: {
    primary: {
      main: customPink[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customPink[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customPink[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customPink[700],
    header: {
      color: "#FFF",
      background: customPink[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customPink[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const cyeanVariant = merge(defaultVariant, {
  name: THEMES.CYAN,
  palette: {
    primary: {
      main: customCyan[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customCyan[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customCyan[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customCyan[700],
    header: {
      color: "#FFF",
      background: customCyan[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customCyan[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});


const yellowWhiteVariant = merge(defaultVariant, {
  name: THEMES.YELLOWWHITE,
  palette: {
    primary: {
      main: customYellowWhite[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customYellowWhite[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customYellowWhite[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customYellowWhite[700],
    header: {
      color: "#FFF",
      background: customYellowWhite[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customYellowWhite[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});


const yellowVariant = merge(defaultVariant, {
  name: THEMES.YELLOW,
  palette: {
    primary: {
      main: customYellow[600],
      contrastText: "#FFF",
    },
    secondary: {
      main: customYellow[400],
      contrastText: "#FFF",
    },
  },
  header: {
    indicator: {
      background: customYellow[600],
    },
  },
  sidebar: {
    color: "#FFF",
    background: customYellow[700],
    header: {
      color: "#FFF",
      background: customYellow[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customYellow[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: grey[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    secondary: {
      main: green[500],
      contrastText: "#FFF",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  sidebar: {
    color: "#FFF",
    background: '#233044',
    header: {
      color: "#FFF",
      background: '#233044',
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: '#233044',
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },

  header: {
    color: grey[300],
    background: "#1B2635",
    search: {
      color: grey[200],
    },
  },
  footer: {
    color: grey[300],
    background: "#233044",
  },
});


const blueVariant = merge(defaultVariant, {
  name: THEMES.BLUE,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[800],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
  },
  sidebar: {
    color: "#FFF",
    background: customBlue[700],
    header: {
      color: "#FFF",
      background: customBlue[800],
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: "#FFF",
      background: customBlue[800],
      online: {
        background: "#FFF",
      },
    },
    badge: {
      color: "#000",
      background: "#FFF",
    },
  },
});

const variants = [
  defaultVariant,
  darkVariant,
  blueVariant,
  redVariant,
  indigoVariant,
  purpleVariant,
  blueGrayVariant,
  orangeVariant,
  tealVariant,
  pinkVariant,
  cyeanVariant,
  yellowVariant,
  yellowWhiteVariant
];

export default variants;
